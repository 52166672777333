import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FaEnvelope, FaPhone, FaGithub, FaLinkedin, FaDownload, FaArrowLeft } from 'react-icons/fa';
import { ThemeContext } from '../../contexts/theme';
import resumeData from './data';

const Resume = () => {
  const [{ themeName }] = useContext(ThemeContext);

  const handleDownload = () => {
    const pdfUrl = '/Saud_Hussain_Resume.pdf';
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.setAttribute('download', 'Saud_Hussain_Resume.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);//add
  };
  

  return (
    <div className={`min-h-screen py-8 sm:py-16 `}>
      <div className="container mx-auto px-4 max-w-4xl">
        {/* Back Button */}
        <div className="mb-6 sm:mb-8">
          <Link to="/">
            <button type="button" className={`${themeName === 'dark' ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white p-2 rounded-full flex items-center transition duration-300`}>
              <FaArrowLeft className="text-white" />
            </button>
          </Link>
        </div>

        {/* Resume Content */}
        <div className={`${themeName === 'dark' ? 'bg-gray-800 text-gray-100' : 'bg-white text-gray-800'} shadow-lg rounded-lg overflow-hidden`}>
          <div className="p-6 sm:p-8">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6">
              <h1 className="text-3xl sm:text-4xl font-bold mb-4 sm:mb-0">{resumeData.name}</h1>
              <button
                type="button"
                onClick={handleDownload}
                className={`${themeName === 'dark' ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white px-4 py-2 rounded-full flex items-center transition duration-300`}
              >
                <FaDownload className="mr-2" /> Download Resume
              </button>
            </div>
            <h2 className={`text-xl sm:text-2xl ${themeName === 'dark' ? 'text-blue-400' : 'text-blue-600'} mb-2`}>{resumeData.title}</h2>
            <p className={`${themeName === 'dark' ? 'text-gray-300' : 'text-gray-600'} mb-6`}>{resumeData.location}</p>

            <div className="flex flex-wrap mb-8">
              <a href={`mailto:${resumeData.contact.email}`} className={`flex items-center ${themeName === 'dark' ? 'text-gray-300' : 'text-gray-600'} mr-6 mb-2`}>
                <FaEnvelope className={`mr-2 ${themeName === 'dark' ? 'text-blue-400' : 'text-blue-600'}`} /> {resumeData.contact.email}
              </a>
              <a href={`tel:${resumeData.contact.phone}`} className={`flex items-center ${themeName === 'dark' ? 'text-gray-300' : 'text-gray-600'} mr-6 mb-2`}>
                <FaPhone className={`mr-2 ${themeName === 'dark' ? 'text-blue-400' : 'text-blue-600'}`} /> {resumeData.contact.phone}
              </a>
              <a href={resumeData.contact.github} target="_blank" rel="noopener noreferrer" className={`flex items-center ${themeName === 'dark' ? 'text-gray-300' : 'text-gray-600'} mr-6 mb-2`}>
                <FaGithub className={`mr-2 ${themeName === 'dark' ? 'text-blue-400' : 'text-blue-600'}`} /> GitHub
              </a>
              <a href={resumeData.contact.linkedin} target="_blank" rel="noopener noreferrer" className={`flex items-center ${themeName === 'dark' ? 'text-gray-300' : 'text-gray-600'} mb-2`}>
                <FaLinkedin className={`mr-2 ${themeName === 'dark' ? 'text-blue-400' : 'text-blue-600'}`} /> LinkedIn
              </a>
            </div>

            <div className="mb-8">
              <h3 className="text-xl sm:text-2xl font-bold mb-4">Summary</h3>
              <p className={`${themeName === 'dark' ? 'text-gray-300' : 'text-gray-600'} leading-relaxed`}>{resumeData.summary}</p>
            </div>

            <div className="mb-8">
              <h3 className="text-xl sm:text-2xl font-bold mb-4">Experience</h3>
              {resumeData.experience.map((job) => (
                <div key={job.id} className="mb-6">
                  <h4 className={`text-lg sm:text-xl font-semibold ${themeName === 'dark' ? 'text-blue-400' : 'text-blue-600'}`}>{job.title}</h4>
                  <p className={`${themeName === 'dark' ? 'text-gray-300' : 'text-gray-600'} font-medium`}>{job.company} | {job.duration}</p>
                  <p className={`${themeName === 'dark' ? 'text-gray-400' : 'text-gray-500'} mb-2`}>{job.location}</p>
                  <p className={`${themeName === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>{job.description}</p>
                </div>
              ))}
            </div>

            <div className="mb-8">
              <h3 className="text-xl sm:text-2xl font-bold mb-4">Projects</h3>
              {resumeData.projects.map((project) => (
                <div key={project.id} className="mb-6">
                  <h4 className={`text-lg sm:text-xl font-semibold ${themeName === 'dark' ? 'text-blue-400' : 'text-blue-600'}`}>{project.name}</h4>
                  <p className={`${themeName === 'dark' ? 'text-gray-300' : 'text-gray-600'} mt-2`}>{project.description}</p>
                </div>
              ))}
            </div>

            <div className="mb-8">
              <h3 className="text-xl sm:text-2xl font-bold mb-4">Skills</h3>
              <div className="flex flex-wrap">
                {resumeData.skills.map((skill) => (
                  <span key={skill} className={`${themeName === 'dark' ? 'bg-blue-700 text-gray-100' : 'bg-blue-100 text-blue-800'} px-3 py-1 rounded-full text-sm mr-2 mb-2`}>
                    {skill}
                  </span>
                ))}
              </div>
            </div>

            <div className="mb-8">
              <h3 className="text-xl sm:text-2xl font-bold mb-4">Languages</h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {resumeData.languages.map((lang) => (
                  <p key={lang.language} className={`${themeName === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
                    <span className="font-semibold">{lang.language}:</span> {lang.level}
                  </p>
                ))}
              </div>
            </div>

            <div>
              <h3 className="text-xl sm:text-2xl font-bold mb-4">Education</h3>
              {resumeData.education.map((edu) => (
                <div key={edu.id} className="mb-4">
                  <h4 className={`text-lg sm:text-xl font-semibold ${themeName === 'dark' ? 'text-blue-400' : 'text-blue-600'}`}>{edu.degree}</h4>
                  <p className={`${themeName === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>{edu.institution}</p>
                  <p className={`${themeName === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>{edu.duration} | {edu.location}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resume;