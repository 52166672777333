const resumeData = {
    name: "SAUD HUSSAIN",
    title: "Software Engineer | Full Stack Developer",
    location: "Dubai, UAE",
    contact: {
      email: "Saudhusayn@gmail.com",
      phone: "+971 527272685",
      website: "SaudHussain.in",
      github:"https://github.com/saud-05",
      linkedin: "https://www.linkedin.com/in/saud-hussain/"
    },
    summary: "Full stack developer specializing in the MERN stack, Next.js, and TypeScript, with over 2 years of practical experience in building efficient web applications from front-end to back-end. Proficient in translating client requirements into functional, user-friendly solutions. Skilled in RESTful API design, database optimization, and integrating AI models. Adept at writing clean, maintainable code while continuously learning and staying updated with the latest industry trends.",
    experience: [
      {
        title: "Full Stack Developer",
        company: "Freelance Work",
        duration: "2022 - Present",
        location: "Remote, India",
        description:"Collaborated with a freelancer to develop responsive front-end components using React, ensuring consistent quality and effective project delivery. Implemented MongoDB and API connections for data management and efficient data flow between front-end and back-end web applications.",

      },
      {
        title: "Software Engineering Intern",
        company: "Varcons Technologies",
        duration: "09/2023 - 10/2023",
        location: "Remote, India",
        description: "Connected a handwriting recognition data model to a web application, enabling real-time conversion of handwritten text into digital format for enhanced user interaction and data processing. Converted Figma design files into a full-stack application, ensuring seamless functionality from front-end to back-end."
      },
      {
        title: "Software Engineer Intern",
        company: "CodeLab Systems",
        duration: "09/2022 - 10/2022",
        location: "Mangalore, India",
        description: "Translated Figma design files into a responsive and visually appealing website, ensuring design fidelity and a seamless user experience. Implemented a user-friendly booking system to allow clients to schedule and manage photography sessions efficiently. Integrated a content management system (CMS) for the photographer to easily update and manage portfolio content."
      }
     
    ],
    education: [
      {
        degree: "Bachelors of Engineering Information Science",
        institution: "Visvsesvaraya Technological University",
        duration: "08/2019 - 07/2023",
        location: "Mangalore, India"
      },
      {
        degree: "Multilevel MERN Coding BootCamp ",
        institution: "The Code Help",
        duration: "08/2023 - 01/2024",
        location: "Remote, India"
      }
    ],
    skills: [
      "JavaScript",
    "TypeScript",
    "Python",
    "Java",
    "C",
    "HTML/CSS",
    "SQL",
    "React.js",
    "Next.js",
    "Node.js",
    "Express.js",
    "Redux Toolkit",
    "Tailwind",
    "Shadcn",
    "React Native",
    "SEO",
    "PostgreSQL",
    "MySQL",
    "MongoDB",
    "DBMS",
    "VS Code",
    "Git",
    "Postman",
    "GitHub",
    "Npm",
    "Vercel",
    "RESTful APIs",
    "Testing (JUnit)",
    "OAuth",
    "WebSocket",
    "WordPress"
    ],
    languages: [
      { language: "English", level: "Advanced" },
      { language: "Urdu", level: "Native" },
      { language: "Hindi", level: "Proficient" }
    ],
    projects: [
      {
        name: "Study Notion-EdTech Platform",
        description: "Developed a full-stack education platform using React, Tailwind CSS, MongoDB, Express.js, and Node.js, allowing users to buy courses and instructors to sell and upload content. Implemented role-based user authentication and authorization, ensuring secure access for students, instructors, and admins. Integrated payment gateway for seamless course purchases, providing a smooth transaction experience for users."
      },
      {
        name: "SaudEstate-RealEstate Marketplace",
        description: "Developed a real estate marketplace application using React, Node.js, Express, and MongoDB, allowing users to browse, buy, and list properties. Integrated advanced search and filtering features to enable users to find properties based on location, price range, and other criteria. Created a REST API to manage property listings, user interactions, and transaction processes, ensuring efficient data handling and communication between the client and server."
      },
      {
        name: " Dynamic CRM Solution",
        description: "Developed a dynamic CRM system to manage client interactions, sales pipelines, and customer support. Integrated features such as lead tracking, sales reporting, and automated notifications to enhance operational efficiency. Ensured data integrity and a seamless user experience by combining React for the frontend and Express.js with MongoDB for the backend."
      },
      {
        name: "ByteBlog-Dynamic Blogging Platform",
        description: "Developed a full-stack blogging web app with Next.js and TypeScript, demonstrating advanced skills in server-side rendering and static site generation. Overcame challenges related to TypeScript integration and type safety, ensuring robust and maintainable code throughout the project. Addressed issues with API performance and state management, implementing effective solutions for smooth user interactions and content management."
      },
      {
        name: "Zostara-Hostel Management System",
        description: "Developed a full-stack blogging web app with React, allowing users to post blogs, comment, and utilize various features. Overcame challenges related to integration, ensuring robust and maintainable code throughout the project. Implemented effective solutions for smooth user interactions and content management."
      }
    ],
    declaration: "I hereby declare that the details mentioned above are correct to the best of my knowledge. I bear the responsibility of any error or mistake in the data, if occurred in the future."
  };
  
  export default resumeData;